export const ADD_MATERIAL = "ADD_MATERIAL";
export const UPDATE_COMPARED = "UPDATE_COMPARED";
export const REMOVE_MATRIX = "REMOVE_MATRIX";
export const SET_ROTATED = "SET_ROTATED";
export const SET_ANGLE_ROTATED = "SET_ANGLE_ROTATED";
export const SET_FRACTION = "SET_FRACTION";
export const SET_RESULTS = "SET_RESULTS";
export const ROTATE_MATRIX = "ROTATE_MATRIX";
export const ROTATE_BY_ANGLE = "ROTATE_BY_ANGLE";
export const RESET_MATRIX = "RESET_MATRIX";
export const CALCULATE = "CALCULATE";
